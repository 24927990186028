@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.my-node-enter {
  opacity: 0;
  transform: scale(0.9);
}
.my-node-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}
.my-node-exit {
  opacity: 1;
  transform: scale(1);
}
.my-node-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

.carousel .slide {
  display: flex;
  justify-content: center;
}

/*.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px; change this to whatever you want
  width: auto;
}
 */
